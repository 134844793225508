import { Component, For } from 'solid-js'
import { PillOption, TogglePillProps } from '~/components/toggle-pill/toggle-pill.interfaces'
import styles from '~/components/toggle-pill/toggle-pill.module.scss'

const TogglePill: Component<TogglePillProps> = ({ options, setOptions }) => {
  const optionClasses = (option: PillOption) => ({
    [styles.option]: true,
    [styles.checked]: Boolean(option.checked)
  })

  const onOptionChange = (checkedOption: PillOption) => () => {
    const updatedOptions = options.map(option => ({
      ...option,
      checked: checkedOption.value === option.value
    }))
    setOptions(updatedOptions)
  }

  return (
    <div class={styles.togglePill}>
      <For each={options}>
        {option => (
          <button
            classList={optionClasses(option)}
            onClick={onOptionChange(option)}
          >
            {option.label}
          </button>
        )}
      </For>
    </div>
  )
}

export default TogglePill
